<template>
  <div class="billDiff">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Reconciliation_date')">
              <!--:default-time="['00:00:00', '23:59:59']"-->
              <el-date-picker
                v-model="dateTime"
                :clearable="false"
                @change="dateTimeChange"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_channels')">
              <el-select v-model="formInline.billType">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="area.channelTypeDesc"
                  :value="area.channelType"
                  :key="area.channelType"
                  v-for="area in payList"
                ></el-option>
                <!-- <el-option label="微信"
                       value="4"></el-option>
            <el-option label="支付宝"
                       value="5"></el-option>
            <el-option label="银联"
                       value="14"></el-option>
            <el-option label="爱泊车"
                       value="31"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Abnormal_classification')">
              <el-select v-model="formInline.diffType">
                <el-option label="全部" value=""></el-option>
                <el-option label="对方有我方没有" value="0"></el-option>
                <el-option label="我方有对方没有" value="1"></el-option>
                <el-option label="双方金额不一致" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_Scenario')">
              <el-select v-model="formInline.orderType">
                <el-option label="全部" value=""></el-option>
                <el-option label="停车订单" value="0"></el-option>
                <el-option label="停车卡订单" value="3"></el-option>
                <el-option label="错峰卡订单" value="6"></el-option>
                <el-option label="预约订单" value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Our_serial_number')">
              <el-input v-model="formInline.sysBillCode"></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Channel_side_serial_number')">
              <el-input v-model="formInline.otherBillCode"></el-input>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button type="" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder" v-if="authority.tabs['billDiff1'].button.export"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button
              type=""
              icon="el-icon-upload2"
              @click="exportData"
              v-if="authority.tabs['billDiff1'].button.export"
              >导出
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="checkboxT"
          disabled="true"
        ></el-table-column>
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          v-if="authority.tabs['billDiff1'].button.singleSupplement"
        >
          <template slot-scope="scope">
            <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
            <el-dropdown
              style="color: #20a0ff; cursor: pointer"
              @command="handleCommand($event, scope.row)"
            >
              <el-button type="text" size="small" style="padding: 0">
                操作
                <i class="el-icon-arrow-down" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="fillUp"
                  v-if="
                    scope.row.diffType == '0' && authority.tabs['billDiff1'].button.singleSupplement
                  "
                >
                  补单
                </el-dropdown-item>
                <!-- <el-dropdown-item command="detail"
                                  v-else>详情</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper" v-if="total != 0">
      <!-- <el-button type="primary"
                 @click="batchFillUp"
                 v-show="authority.tabs['billDiff1'].button.singleSupplement">补单
      </el-button> -->
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--弹出框详情-->
    <billDiffDetail
      ref="billDiffDetail"
      :colorRed="colorNumber"
      :mynumber="mynumber"
      :transFlowListIds="transFlowListIds"
      :detailType="detailType"
      :title="title"
      :billType="billType"
      :transFlowList="transFlowList"
      :transFlowListLabel="transFlowListLabel"
      :orderList="orderList"
      :orderListLabel="orderListLabel"
    ></billDiffDetail>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import billDiffDetail from "@/components/dialogDetail/billDiffDetail.vue";

export default {
  name: "billDiffList",
  components: {
    billDiffDetail,
  },
  data() {
    return {
      sysBillCode: "",
      otherBillCode: "",
      renList: [],
      payList: [],
      authority: this.$route.meta.authority,
      detailType: "billDiffList",
      transFlowListIds: [],
      billType: "",
      title: "补单",
      total: 0,
      page: 1,
      mynumber: 1,
      colorNumber: 2,
      pageSize: 15,
      dateTime: [],
      form: {
        billType: "",
      },
      formInline: {
        startDate: "",
        endDate: "",
        billType: "",
        diffType: "",
        ren: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: "对账日期",
          prop: "billDate",
          formatter: (row, column) => {
            if (row.billDate.length > 10) {
              return row.billDate.slice(0, 10);
            }
            return row.billDate;
          },
        },
        {
          label: "账单类型",
          prop: "billTypeDesc",
        },
        {
          label: this.$t("list.Payment_merchant"),
          prop: "tenantName",
        },
        {
          label: "我方支付时间",
          prop: "sysDate",
        },
        {
          label: "我方交易金额",
          prop: "sysMoney",
          formatter: (row) => {
            if (row.sysMoney) {
              return (row.sysMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付场景",
          prop: "orderTypeDesc",
        },
        {
          label: "我方流水号",
          prop: "sysBillCode",
        },
        {
          label: "对方支付时间",
          prop: "otherDate",
        },
        {
          label: "对方金额",
          prop: "otherMoney",
          formatter: (row) => {
            if (row.otherMoney) {
              return (row.otherMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        // {
        //   label: '支付场景',
        //   prop: 'difBillId',
        //   formatter: (row) => {
        //     if (row.orderTypeDesc) {
        //       return row.orderTypeDesc
        //     } else {
        //       return ''
        //     }
        //   }
        // },
        {
          label: "对方流水号",
          prop: "otherBillCode",
        },
      ],
      transFlowList: [],
      transFlowListLabel: [
        {
          label: this.$t("list.payment_time"),
          prop: "dealTime",
        },
        {
          label: "交易金额1",
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付场景",
          prop: "orderTypeDesc",
        },
        {
          label: "我方流水号",
          prop: "sysTradeNo",
        },
        {
          label: "第三方流水号",
          prop: "outTradeNo",
        },
      ],
      orderList: [],
      orderListLabel: [],
      activeSelectTableItem: [],
      activetransFlowListIds: [],
    };
  },
  methods: {
    checkboxT(row) {
      if (row.diffType == "0") {
        return 1;
      } else {
        return 0;
      }
    },
    handleSelectionChange(val) {
      this.activeSelectTableItem = [];
      this.activetransFlowListIds = [];
      val.forEach((v) => {
        if (v.diffType == "0") {
          this.activeSelectTableItem.push(v);
          this.activetransFlowListIds.push(v.sysBillCode);
        }
      });
    },
    dateTimeChange(val) {
      this.formInline.startDate = val[0];
      this.formInline.endDate = val[1];
    },
    exportData() {
      exportExcelNew("/acb/2.0/pendingOrder/download", this.formInline);
    },
    resetForm() {
      let startTime = new Date();
      let endTime = new Date();
      startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
      this.formInline = {
        billType: "",
        diffType: "",
      };
      this.endTime = endTime;
      this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
      this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
      this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    },
    // 处理操作相关动作
    handleCommand(command, data) {
      if (command == "fillUp") {
        this.detailType = "billDiffList";
      } else if (command == "detail") {
        this.detailType = "detail";
      }
      if (data.orderType == 0) {
        // 停车订单
        this.orderListLabel = [
          {
            label: "订单号",
            // prop: 'businessOrderId'
            prop: "payOrderId",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.entry_time"),
            // prop: 'entryTime'
            prop: "entryTimeStr",
          },
          {
            label: this.$t("list.Appearance_time"),
            // prop: 'exitTime'
            prop: "exitTimeStr",
          },
          {
            label: this.$t("list.amount_receivable"),
            prop: "shouldPay",
            formatter: (row) => {
              if (row.shouldPay) {
                return (row.shouldPay / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.preferential_amount"), // todo
            prop: "agioPay",
            formatter: (row) => {
              if (row.agioPay) {
                return (row.agioPay / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.Actual_received_amount"),
            prop: "money",
            formatter: (row) => {
              if (row.money) {
                return (row.money / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeDesc",
          },
          {
            label: "支付状态",
            // prop: 'orderStatusDesc'
            prop: "payStateName",
          },
          {
            label: "我方流水号",
            // prop: 'paymentId'
            prop: "sysTradeNo",
          },
          // {
          //   label: '支付商户',
          //   prop: 'tenantName'
          // },
        ];
      } else if (data.orderType == 6) {
        // 错峰卡订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "mobile",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "actualMoney",
            formatter: (row) => {
              if (row.actualMoney) {
                return (row.actualMoney / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeName",
          },
          {
            label: "我方流水号",
            prop: "sysTradeNo",
          },
        ];
      } else if (data.orderType == 5) {
        // 预约订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "mobile",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "payMoney",
            formatter: (row) => {
              if (row.payMoney) {
                return (row.payMoney / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeName",
          },
          {
            label: "我方流水号",
            prop: "sysTradeNo",
          },
        ];
      } else if (data.orderType == 3) {
        // 停车卡订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "phoneNumber",
          },
          {
            label: "商品名称",
            prop: "parkCardRuleName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "actualAmount",
            formatter: (row) => {
              if (row.actualAmount) {
                return (row.actualAmount / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.Payment_channels"),
            prop: "channelTypeDesc",
          },
          {
            label: this.$t("list.Payment_devices"),
            prop: "devTypeDesc",
          },
          {
            label: "购买渠道",
            prop: "buyTypeDesc",
          },
          {
            label: "我方流水号",
            prop: "tradeCode",
          },
        ];
      }
      this.transFlowListLabel = [
        {
          label: this.$t("list.payment_time"),
          // prop: 'dealTime'
          prop: "transTimeStr",
        },
        {
          label: "交易金额",
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付场景",
          // prop: 'orderTypeDesc'
          prop: "businessOrderTypeName",
        },
        {
          label: this.$t("list.Payment_merchant"),
          // prop: 'tenantName'
          prop: "mchName",
        },
        {
          label: "我方流水号",
          // prop: 'sysTradeNo'
          prop: "tradeNo",
        },
        {
          label: "第三方流水号",
          prop: "outTradeNo",
        },
      ];
      this.$refs.billDiffDetail.dialogVisibleShow();
      this.transFlowListIds = [data.sysBillCode];
      this.billType = data.billType;
      this.fillUp(data);
    },
    batchFillUp() {
      if (this.activeSelectTableItem.length > 0) {
        this.transFlowList = this.activeSelectTableItem;
        this.transFlowListIds = this.activetransFlowListIds;
        this.transFlowListLabel = this.tableCols;
        this.detailType = "batchFillUp";
        this.$refs.billDiffDetail.dialogVisibleShow();
      } else {
        this.$alert("请选择补单信息");
      }
    },
    fillUp(data) {
      this.$axios
        .get("/acb/2.0/pendingOrder/previewMakeUp", {
          data: {
            tradeCode: data.sysBillCode,
            orderType: data.orderType,
            billType: data.billType,
          },
        })
        .then((res) => {
          // this.transFlowList = res.value.transFlowList
          // this.orderList = res.value.orderList
          this.transFlowList = res.value.systemBillVOList;
          if (data.orderType == 0) {
            this.orderList = res.value.payOrderVOList;
          } else if (data.orderType == 3) {
            this.orderList = res.value.parkCardVOList;
          } else if (data.orderType == 5) {
            this.orderList = res.value.parkAppointmentOrderVOList;
          } else if (data.orderType == 6) {
            this.orderList = res.value.staggeredOrderVOList;
          }
        })
        .catch(() => {
          this.transFlowList = [];
          this.orderList = [];
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/pendingOrder/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.value) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        });
    },
    getPayType(data) {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          // console.log("测试", res)
          this.payList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
  },
  activated() {
    if (this.$route.params.tenantId) {
      this.formInline.startDate = this.$route.params.startDate;
      this.formInline.endDate = this.$route.params.endDate;
      this.formInline.tenantId = this.$route.params.tenantId;
      this.formInline.billType = parseInt(this.$route.params.selectData);
      this.dateTime = [this.$route.params.startDate, this.$route.params.endDate];
      this.searchData();
    }
  },
  mounted() {
    let startTime = new Date();
    let endTime = new Date();
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
    this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
    this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
    this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    this.searchData();
    this.$EventBus.$on("billDifListSearchData", this.searchData);
    this.getPayType();
  },
};
</script>

<style lang="stylus" scoped rel="stylesheet/stylus">
.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
  button
    float left
</style>
