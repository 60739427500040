<template>
  <div class="billDiffHistory">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="" style="min-width: 120px">
              <el-select
                v-model.trim="formInline.dateType"
                filterable
                placeholder=""
                style="width: 120px"
              >
                <el-option :label="$t('searchModule.Reconciliation_date')" value="0"></el-option>
                <el-option label="平账日期" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                v-model="dateTime"
                :clearable="false"
                type="daterange"
                @change="dateTimeChange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Our_serial_number')">
              <el-input v-model="formInline.sysTradeNo"></el-input>
            </el-form-item>
            <el-form-item label="对方流水号">
              <el-input v-model="formInline.outTradeNo"></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Handling_method')">
              <el-select v-model.trim="formInline.dealType" filterable placeholder="">
                <el-option label="全部" value=""></el-option>
                <el-option label="补单" value="1"></el-option>
                <!-- <el-option label="重复支付退款" value="2"></el-option>
            <el-option label="修改订单退款" value="3"></el-option>
            <el-option label="删单" value="4"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Operator')">
              <el-input v-model="formInline.operatorName"></el-input>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="tableWrapper">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
      >
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="80"
          v-if="authority.tabs['billDif2'].button.detail"
        >
          <template slot-scope="scope">
            <el-dropdown style="color: #20a0ff; cursor: pointer" @command="ctrlData(scope.row)">
              <el-button type="text" size="small" style="padding: 0">
                操作
                <i class="el-icon-arrow-down" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="detaile" v-if="authority.tabs['billDif2'].button.detail"
                  >详情
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--弹出框详情-->
    <billDiffDetail
      ref="billDiffDetail"
      :colorRed="colorNumber"
      detailType="detail"
      :title="title"
      :mynumber="mynumber"
      :transFlowList="transFlowList"
      :transFlowListLabel="transFlowListLabel"
      :orderList="orderList"
      :orderListLabel="orderListLabel"
    ></billDiffDetail>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
import billDiffDetail from "@/components/dialogDetail/billDiffDetail.vue";
export default {
  name: "billDiffHistory",
  components: {
    billDiffDetail,
  },
  data() {
    return {
      mynumber: 1,
      authority: this.$route.meta.authority,
      title: "补单",
      dateTime: [],
      total: 0,
      page: 1,
      pageSize: 15,
      colorNumber: 2,
      formInline: {
        outTradeNo: "",
        sysTradeNo: "",
        dealType: "",
        dateType: "0",
        startDate: "",
        endDate: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: "对账日期",
          prop: "originBillDate",
          formatter: (row, column) => {
            if (row.originBillDate.length > 10) {
              return row.originBillDate.slice(0, 10);
            }
            return row.originBillDate;
          },
        },
        {
          label: "我方流水号",
          prop: "sysTradeNo",
        },
        {
          label: "对方流水号",
          prop: "outTradeNo",
        },
        {
          label: "支付金额",
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "处理方式",
          prop: "dealType",
          formatter: (row) => {
            if (row.dealType) {
              // eslint-disable-next-line default-case
              switch (row.dealType) {
                case 1:
                  return "补单";
                case 2:
                  return "重复支付退款";
                case 3:
                  return "修改订单退款";
                case 4:
                  return "删单";
              }
            } else {
              return "暂无";
            }
          },
        },
        {
          label: this.$t("list.processing_time"),
          prop: "dealTime",
        },
        {
          label: this.$t("list.Operator"),
          prop: "operatorName",
        },
      ],
      transFlowList: [],
      transFlowListLabel: [
        {
          label: this.$t("list.payment_time"),
          // prop: 'dealTime'
          prop: "transTimeStr",
        },
        {
          label: "交易金额",
          prop: "money",
          formatter: (row) => {
            if (row.money) {
              return (row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付场景",
          // prop: 'orderTypeDesc'
          prop: "businessOrderTypeName",
        },
        {
          label: this.$t("list.Payment_merchant"),
          // prop: 'tenantName'
          prop: "mchName",
        },
        {
          label: "我方流水号",
          // prop: 'sysTradeNo'
          prop: "tradeNo",
        },
        {
          label: "第三方流水号",
          prop: "outTradeNo",
        },
      ],
      orderList: [],
      orderListLabel: [],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    ctrlData(data) {
      if (data.orderType == 0) {
        // 停车订单
        this.orderListLabel = [
          {
            label: "订单号",
            // prop: 'businessOrderId'
            prop: "payOrderId",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.entry_time"),
            // prop: 'entryTime'
            prop: "entryTimeStr",
          },
          {
            label: this.$t("list.Appearance_time"),
            // prop: 'exitTime'
            prop: "exitTimeStr",
          },
          {
            label: this.$t("list.amount_receivable"),
            prop: "shouldPay",
            formatter: (row) => {
              if (row.shouldPay) {
                return (row.shouldPay / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.preferential_amount"), // todo
            prop: "agioPay",
            formatter: (row) => {
              if (row.agioPay) {
                return (row.agioPay / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.Actual_received_amount"),
            prop: "money",
            formatter: (row) => {
              if (row.money) {
                return (row.money / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeDesc",
          },
          {
            label: "支付状态",
            // prop: 'orderStatusDesc'
            prop: "payStateName",
          },
          {
            label: "我方流水号",
            // prop: 'paymentId'
            prop: "sysTradeNo",
          },
          // {
          //   label: '支付商户',
          //   prop: 'tenantName'
          // },
        ];
      } else if (data.orderType == 6) {
        // 错峰卡订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "mobile",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "actualMoney",
            formatter: (row) => {
              if (row.actualMoney) {
                return (row.actualMoney / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeName",
          },
          {
            label: "我方流水号",
            prop: "sysTradeNo",
          },
        ];
      } else if (data.orderType == 5) {
        // 预约订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "mobile",
          },
          {
            label: this.$t("list.plate_number"),
            prop: "plateNumber",
          },
          {
            label: this.$t("list.park_name"),
            prop: "parkName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "payMoney",
            formatter: (row) => {
              if (row.payMoney) {
                return (row.payMoney / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: "支付方式",
            prop: "payTypeName",
          },
          {
            label: "我方流水号",
            prop: "sysTradeNo",
          },
        ];
      } else if (data.orderType == 3) {
        // 停车卡订单
        this.orderListLabel = [
          {
            label: this.$t("list.phone_number"),
            prop: "phoneNumber",
          },
          {
            label: "商品名称",
            prop: "parkCardRuleName",
          },
          {
            label: this.$t("list.Pay_amount"),
            prop: "actualAmount",
            formatter: (row) => {
              if (row.actualAmount) {
                return (row.actualAmount / 100).toFixed(2);
              } else {
                return "0.00";
              }
            },
          },
          {
            label: this.$t("list.Payment_channels"),
            prop: "channelTypeDesc",
          },
          {
            label: this.$t("list.Payment_devices"),
            prop: "devTypeDesc",
          },
          {
            label: "购买渠道",
            prop: "buyTypeDesc",
          },
          {
            label: "我方流水号",
            prop: "tradeCode",
          },
        ];
      }
      this.getDetail(data);
    },
    getDetail(data) {
      this.$axios
        .get("/acb/2.0/flatAccountRecord/detail", {
          data: {
            flatAccountRecordId: data.flatAccountRecordId,
          },
        })
        .then((res) => {
          this.transFlowList = res.value.systemBillVOList;
          // this.orderList = res.value.orderList
          if (data.orderType == 0) {
            this.orderList = res.value.payOrderVOList;
          } else if (data.orderType == 3) {
            this.orderList = res.value.parkCardVOList;
          } else if (data.orderType == 5) {
            this.orderList = res.value.parkAppointmentOrderVOList;
          } else if (data.orderType == 6) {
            this.orderList = res.value.staggeredOrderVOList;
          }
          this.$refs.billDiffDetail.dialogVisibleShow(res.value.remark);
        });
    },
    dateTimeChange(val) {
      this.formInline.startDate = val[0];
      this.formInline.endDate = val[1];
    },
    validateTime() {
      let diff =
        new Date(this.dateTime[1]).getTime() / (1000 * 3600 * 24) -
        new Date(this.dateTime[0]).getTime() / (1000 * 3600 * 24);
      if (diff > 30) {
        this.$alert("最多查询31天数据");
        return false;
      } else {
        return true;
      }
    },
    searchData() {
      if (!this.validateTime()) return false;
      this.formInline.page = this.page;
      this.formInline.pageSize = this.pageSize;
      this.$axios
        .get("/acb/2.0/flatAccountRecord/list", {
          data: this.formInline,
        })
        .then((res) => {
          if (res.value && res.value.list.length > 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        });
    },
  },
  activated() {
    if (this.$route.params.tenantId) {
      this.formInline.startDate = this.$route.params.startDate;
      this.formInline.endDate = this.$route.params.endDate;
      this.formInline.tenantId = this.$route.params.tenantId;
      this.dateTime = [this.$route.params.startDate, this.$route.params.endDate];
      this.searchData();
    }
  },
  mounted() {
    let startTime = new Date();
    let endTime = new Date();
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 30);
    this.startTime = startTime;
    this.endTime = endTime;
    this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
    this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
    this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
  },
};
</script>

<style lang="stylus" scoped rel="stylesheet/stylus">

.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
</style>
