var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "bgFFF",
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleTab },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _vm.authority.tabs["billDiff1"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "挂单列表", name: _vm.name1 } },
                  [_c("billDiffList", { ref: "billDiffList" })],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["billDif2"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "平账历史", name: _vm.name2 } },
                  [_c("billDiffHistory", { ref: "billDiffHistory" })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }